/* @flow */

import * as React from 'react'
import { ProductTemplate } from '../templates'

type Props = {
  location: {
    href: string,
    state: Object,
  },
}

export default function Form(props: Props) {
  const { location } = props
  return (
    <ProductTemplate
      url={location.href}
      title="Caviste et épicier, vendez en ligne avec les plugins e-commerce Wino"
      media="illu_product-ecommerce.png"
      name="E-commerce"
      label="E-commerce"
      description="Grâce à nos plugins e-commerce Shopify, Woocommerce et Prestashop, connectez votre site en ligne caviste ou épicier à la caisse enregistreuse Wino pour gérer et centraliser tous vos canaux de distribution automatiquement."
      body={[
        {
          name: 'features',
          items: [
            {
              media: 'icon_product-ecommerce_catalogue.png',
              title: 'Un catalogue unique',
              content:
                'Créez un catalogue centralisé entre la caisse enregistreuse et le site e-commerce',
            },
            {
              media: 'icon_product-ecommerce_stock.png',
              title: 'Des stocks synchronisés',
              content:
                "Vendez en ligne vos produits de cave et d'épicerie et maîtriser la gestion de vos stocks",
            },
            {
              media: 'icon_product-ecommerce_quick.png',
              title: 'Facile à installer',
              content: 'Connectez facilement le logiciel de caisse Wino et votre site e-commerce ',
            },
          ],
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'crater',
              media: 'screenshot_ecommerce_catalogue.png',
              alt: 'catalogue centralisé',
              label: 'Un catalogue centralisé',
              title: "Gagnez du temps pour créer et gérer vos produits de cave et d'épicerie",
              content:
                'Avec le module e-commerce installé, le catalogue produit de la caisse est importé automatiquement sur le backoffice de votre solution e-commerce Prestashop, Woocommerce ou Shopify. La création de nouveaux produits et les modifications de produits existants sont ensuite optimisées grâce à une synchronisation automatique entre le site e-commerce et la caisse enregistreuse. Fini les saisies manuelles et les erreurs ! ',
            },
            {
              decoration: 'mexicali',
              media: 'screenshot_ecommerce_stockmanagement.png',
              alt: 'gestion de stock optimisée',
              label: 'Une gestion de stocks optimisée',
              title: 'Assurez un suivi optimal de vos stocks en ligne et en boutique',
              content:
                'Votre gestion de stock est automatisée. Vos stocks sont mis à jour sur le site en ligne d’après les données du logiciel Wino et vos stocks en boutique sont actualisés d’après les commandes sur le site. Vous êtes assuré que les produits proposés en ligne sont bien disponibles pour les internautes. Le module e-commerce est une solution optimale pour maîtriser la gestion des stocks sur vos différents canaux de vente (site de vente en ligne, boutique physique).',
            },
          ],
        },
        {
          name: 'informationInsert',
          items: [
            {
              media: 'oncashregister',
              title: 'Être accompagné',
              content: `Pour vous aider à mettre en place votre site e-commerce, nous vous recommandons deux partenaires de confiance : LobsTTer, spécialisé dans la création de sites Shopify et Creabilis, spécialisé dans la création de site Prestashop. N'hésitez pas à les contacter pour leur exposer votre projet.`,
            },
            {
              media: 'free',
              title: 'On parle e-commerce ensemble ?',
              content:
                "Pour plus d'informations sur nos connecteurs e-commerce ou tout simplement pour échanger avec nous sur votre projet, n'hésitez pas à prendre rendez-vous dès maintenant avec un expert Wino.",
              href: 'https://wino.fr/contact/',
              linkText: 'Échanger avec un expert',
            },
          ],
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'landing',
              media: 'screenshot_ecommerce_plugandplay.png',
              alt: "se lancer dans l'e-commerce",
              label: "Se lancer dans l'e-commerce",
              title: 'Mettez en place le module e-commerce de Wino simplement',
              content:
                'Lancer un site e-commerce et en assurer le maintien est un travail de longue haleine. Pour gagner du temps sur la gestion du catalogue et des stocks, Wino vous propose un module e-commerce très simple à installer sur notre backoffice Prestashop, Wommexrce ou Shopify. Cinq minutes suffisent ! Nos partenaires : LobsTTEr pour Shopify et Créabilis pour Prestashop,  sont également là pour vous accompagner. ',
            },
          ],
        },
      ]}
    />
  )
}
